<template>
  <div>
    <div class="mx-2">
      <span class="titulo-pagina">
        {{ $t('modulos.procedimentos_eletronicos.botoes.associar_usuarios') }}
      </span>
      <div>
        <v-chip
          class="mr-1"
          small
        >
          {{ `Procedimento Eletrônico: ${procedimento}` }}
        </v-chip>
      </div>
    </div>
    <div class="flex row justify-space-between my-2 px-4">
      <botao-padrao
        v-if="podeExcluir"
        outlined
        color="danger"
        @click="excluir"
      >
        <v-icon>$mdiTrashCanOutline</v-icon>
        {{ $t('geral.botoes.excluir') }}
      </botao-padrao>
      <div />
      <div class="flex row justify-end">
        <botao-padrao
          class="mr-2"
          @click="abrirUsuariosModal"
        >
          {{
            $t('modulos.procedimentos_eletronicos.usuarios.associar_usuarios')
          }}
        </botao-padrao>
        <botao-padrao @click="abrirTecnicosModal">
          {{
            $t('modulos.procedimentos_eletronicos.usuarios.associar_tecnicos')
          }}
        </botao-padrao>
      </div>
    </div>

    <accordion-padrao
      :titulo="
        $t('modulos.procedimentos_eletronicos.usuarios.usuarios_signatarios')
      "
      class="col-12 col-md-12 pt-0"
      alterar-cor
      cor-azul-texto
      obrigatorio
    >
      <tabela-padrao-prime-vue
        ref="tabela"
        v-model="tabelaUsuarios.selecionados"
        class="mt-2"
        ajustar-altura-linha
        :colunas="tabelaUsuarios.colunas"
        :dados="tabelaUsuarios.dados"
        :mostrar-acoes="false"
        sem-paginacao
      />
    </accordion-padrao>
    <accordion-padrao
      :titulo="
        $t('modulos.procedimentos_eletronicos.usuarios.tecnicos_executantes')
      "
      class="col-12 col-md-12 pt-0"
      alterar-cor
      cor-azul-texto
      obrigatorio
    >
      <tabela-padrao-prime-vue
        ref="tabela"
        v-model="tabelaExecutantes.selecionados"
        class="mt-2"
        ajustar-altura-linha
        :colunas="tabelaExecutantes.colunas"
        :dados="tabelaExecutantes.dados"
        :mostrar-acoes="false"
        sem-paginacao
      />
    </accordion-padrao>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
    <usuarios-modal
      ref="usuarios-modal"
      :titulo="$t('modulos.procedimentos_eletronicos.usuarios.usuarios_signatarios')"
    />
    <usuarios-modal
      ref="tecnicos-modal"
      :titulo="$t('modulos.procedimentos_eletronicos.usuarios.tecnicos_executantes')"
    />
  </div>
</template>
<script>
import UsuariosModal from './components/modal/UsuariosModal.vue';
import ProcedimentoEletronicoService from '@/common/services/cadastros/ProcedimentoEletronicoService';

export default {
  components: {
    UsuariosModal,
  },
  props: {
    id: { type: String, default: '' },
  },
  data() {
    return {
      procedimento: null,
      tabelaUsuarios: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.procedimentos_eletronicos.usuarios.nome'),
            sortable: true,
          },
          {
            value: 'login',
            text: this.$t('modulos.procedimentos_eletronicos.usuarios.login'),
            sortable: true,
          },
          {
            value: 'email',
            text: this.$t('modulos.procedimentos_eletronicos.usuarios.email'),
            sortable: true,
          },
          {
            value: 'papel.nome',
            text: this.$t('modulos.procedimentos_eletronicos.usuarios.papel'),
            sortable: true,
          }
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      tabelaExecutantes: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.procedimentos_eletronicos.usuarios.nome'),
            sortable: true,
          },
          {
            value: 'login',
            text: this.$t('modulos.procedimentos_eletronicos.usuarios.login'),
            sortable: true,
          },
          {
            value: 'email',
            text: this.$t('modulos.procedimentos_eletronicos.usuarios.email'),
            sortable: true,
          },
          {
            value: 'papel.nome',
            text: this.$t('modulos.procedimentos_eletronicos.usuarios.papel'),
            sortable: true,
          }
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  computed: {
    podeExcluir() {
      return (
        this.tabelaUsuarios.selecionados.length > 0 ||
        this.tabelaExecutantes.selecionados.length > 0
      );
    },
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.procedimentos_eletronicos.titulos.listagem')
    );
    this.buscar();
  },
  methods: {
    buscar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ProcedimentoEletronicoService.buscar(this.id)
        .then((res) => {
          this.procedimento = res.data.codigo + ' - ' + res.data.nome;
          this.tabelaExecutantes.dados = res.data?.usuariosExecutantes;
          this.tabelaUsuarios.dados = res.data?.usuariosSignatarios;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar() {
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'procedimentos-eletronicos' });
      });
    },
    abrirUsuariosModal() {
      this.$refs['usuarios-modal'].abrirModal(this.tabelaUsuarios);
    },
    abrirTecnicosModal() {
      this.$refs['tecnicos-modal'].abrirModal(this.tabelaExecutantes);
    },
    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabelaUsuarios.selecionados.forEach((element) => {
          this.tabelaUsuarios.dados.splice(
            this.tabelaUsuarios.dados.indexOf(element),
            1
          );
        });
        this.tabelaExecutantes.selecionados.forEach((element) => {
          this.tabelaExecutantes.dados.splice(
            this.tabelaExecutantes.dados.indexOf(element),
            1
          );
        });
        this.tabelaExecutantes.selecionados = [];
        this.tabelaUsuarios.selecionados = [];
      });
    },
    salvar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      const payload = {
        usuariosSignatariosIds: this.tabelaUsuarios.dados.map(el => el.id),
        usuariosExecutantesIds: this.tabelaExecutantes.dados.map(el => el.id),
      };

      ProcedimentoEletronicoService.associarUsuarios(this.id, payload).then(() => {
        this.toastSucesso("Usuários associados com sucesso!");
        this.$router.push({ name: 'procedimentos-eletronicos' });
      }).catch(() => {
        this.toastErro("Erro ao associar usuários!");
      }).finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
      });
    },
  },
};
</script>
