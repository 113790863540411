<template lang="">
  <modal-padrao
    ref="modal-usuarios"
    :max-width="1000"
    :titulo="titulo"
    @modalFechado="modalFechado"
    :titulo-ok="$t('geral.botoes.confirmar')"
    @ok="aplicarSelecionados"
  >
    <modal-usuarios v-model="selecionados" :show="modalAberto" />
  </modal-padrao>
</template>
<script>
import ModalUsuarios from '@/components/inputs/especificos/InputUsuarios/modal';
import _ from 'lodash';

export default {
  components: { ModalUsuarios },
  props: {
    titulo: { type: String, default: '' },
  },
  data() {
    return {
      modalAberto: false,
      selecionados: [],
      tabelaReferencia: {},
    };
  },
  methods: {
    abrirModal(tabela) {
      this.tabelaReferencia = tabela;
      this.selecionados = _.cloneDeep(tabela.dados);
      this.modalAberto = true;
      this.$refs['modal-usuarios'].abrirModal();
    },
    aplicarSelecionados() {
      this.tabelaReferencia.dados = _.cloneDeep(this.selecionados);
      this.$refs['modal-usuarios'].fecharModal();
    },
    modalFechado() {
      this.modalAberto = false;
    },
  },
};
</script>
